import React from "react"
import { Card, CardContent, Divider, withStyles } from "@material-ui/core"
import contactCardStyle from "./contactCardStyle"
import { UFFICIO_MILANO, UFFICIO_ROMA } from "../../constants/Uffici"
import format from '../../utils/format'

const ContactCard = ({ classes, content, zoomOnMarker }) => {
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <h1 className={classes.subtitle}>{content.subtitle}</h1>
        <h2 className={classes.title}>{content.title}</h2>
        <Divider className={classes.divider} />
        <div>
          <h3 className={classes.sectionTitle}>{content.address.title}</h3>
          <p className={`${classes.sectionFirstContent} ${classes.link}`} onClick={() => zoomOnMarker(UFFICIO_MILANO.markerId)}>{UFFICIO_MILANO.address}</p>
          <p className={`${classes.sectionContent} ${classes.link}`} onClick={() => zoomOnMarker(UFFICIO_ROMA.markerId)}>{UFFICIO_ROMA.address}</p>
        </div>
        <div>
          <h3 className={classes.sectionTitle}>{content.phone.title}</h3>
          <a href={`tel:${content.phone.phone1}`} className={`${classes.sectionFirstContent} ${classes.link}`}>{format.phoneNumberFormat(content.phone.phone1)}</a>
          <a href={`tel:${content.phone.phone2}`} className={`${classes.sectionContent} ${classes.link}`}>{format.phoneNumberFormat(content.phone.phone2)}</a>
        </div>
        <div>
          <h3 className={classes.sectionTitle}>{content.email.title}</h3>
          <a href={`mailto:${content.email.email}`} className={`${classes.sectionLastContent}  ${classes.link}`}>{content.email.email}</a>
        </div>
      </CardContent>
    </Card>
  )
}

export default withStyles(contactCardStyle)(ContactCard)
