export const UFFICIO_MILANO = {
  markerId: "ufficio_milano",
  address: "Via Savona, 52 20144 Milano",
  coordinates: {
    lat: 45.45409,
    lng: 9.16235,
  },
}
export const UFFICIO_ROMA = {
  markerId: "ufficio_roma",
  address: "Via Paisiello, 19 00198 Roma",
  coordinates: {
    lat: 41.91686,
    lng: 12.49357,
  },
}
